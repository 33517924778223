<!-- eslint-disable -->
<template>
  <VCard :collapsed="isCollapsed" title-class="bg-theme-5">
    <template #title>
      <div class="flex items-center">
        <VImage
          :color="resource.colour"
          :name="getText(resource.texts, 'name')"
          class="mr-4"
        />

        <div>
          <div class="label text-xs font-light uppercase">
            {{ $t(`backend.${resource.outline_object_type}`) }}
          </div>

          {{ getText(resource?.texts, "name") }}
        </div>
      </div>
    </template>

    <template #title-actions>
      <div class="flex items-center">
        <VDrag :readonly="readonly" />

        <VDeleteAction
          :item="resource"
          :disabled="readonly"
          :text-value="getText(resource?.texts, 'name')"
          :delete-function="onClickDelete"
        />

        <VAction
          :icon="isCollapsed ? 'expand' : 'collapse'"
          :label="$t(`app.${isCollapsed ? 'expand' : 'collapse'}`)"
          @click="onClickToggle"
        />

        <VAction
          icon="redirect"
          :label="$t('app.redirect')"
          @click="onClickRedirect"
        />
      </div>
    </template>

    <template #content>
      <div class="version">
        {{ $t("app.versions") }} {{ resource?.version }}
      </div>

      <div class="mb-4">
        {{ trimHTML(getText(resource?.texts, "details")) }}
      </div>

      <div class="grid gap-2">
        <VLine :label="$t('app.type')">
          <template #value>
            <div class="lesson-type">
              {{ resource.type }}
            </div>
          </template>
        </VLine>

        <VLine
          :label="$t('app.duration')"
          :value="`${resource?.duration} ${lowercase($t(`app.${resource.duration_type}`))}`"
        />

        <VLine
          :label="$t('app.maximum_attempts')"
          :value="
            resource.maximum_attempts_per_registration ??
              $t('app.unlimited_attempts')
          "
        />

        <VLine
          :label="$t('app.interval_between_attempts')"
          :value="resource.reevaluations_interval_in_days"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
// Composables
import useQuiz from "@/composables/useQuiz";
import useObservation from "@/composables/useObservation";
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
import useRequest from "@/composables/useRequest";
import useColor from "@/composables/useColor";
// Components
import VCard from "@/components/VCard";
import VDeleteAction from "@/components/VDeleteAction";
import VAction from "@/components/tables/VAction";
import VDrag from "@/components/VDrag";
import VImage from "@/components/VImage";
import VLine from "@/components/VLine";

export default {
  components: {
    VCard,
    VDeleteAction,
    VDrag,
    VAction,
    VImage,
    VLine
  },
  props: {
    // trainingId
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    collapsedAll: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    // Misc
    const router = useRouter();

    // Composables
    const { getText } = useTexts();
    const { trimHTML, lowercase } = useDisplay();
    const { request } = useRequest();
    const { route: quizRoute } = useQuiz();
    const { route: observationRoute } = useObservation();
    const { getContrastingColor } = useColor();

    // Data
    const isCollapsed = ref(false);

    // Methods
    const onClickToggle = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    const onClickDelete = async () => {
      await request({
        endpoint: "teaching.trainings.remove-outline-item",
        pathParams: {
          id: props.id,
          type: props.resource?.outline_object_type,
          item_id: props.resource?.id
        },
        showToaster: false
      });

      context.emit("refresh");
    };

    const onClickRedirect = () => {
      const routes = {
        Quiz: quizRoute,
        Observation: observationRoute
      };

      const route = routes[props.resource.type];

      const url = router.resolve({
        name: `${route}-details`,
        params: {
          id: props.resource.original_id
        }
      });

      window.open(url.href, "_blank");
    };

    // Watch
    watch(
      () => props.collapsedAll,
      () => {
        isCollapsed.value = props.collapsedAll;
      }
    );

    return {
      onClickRedirect,
      onClickToggle,
      isCollapsed,
      onClickDelete,
      // useColor
      getContrastingColor,
      // useDisplay
      trimHTML,
      lowercase,
      // useTexts
      getText
    };
  }
};
</script>
