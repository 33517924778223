import { ref, provide, watch } from "vue";

/**
 * @param {Array} localizedFields
 * @param {Array} texts
 * @returns {Object}
 */
export default function useLang(args = {}) {
  const { localizedFields = [], texts = [] } = args;

  // Methods
  const updateLang = updatedLang => {
    lang.value = updatedLang;
  };

  const getLanguage = (texts, fields) => {
    const defaultValue = "";

    if (!texts.value || texts.value.length === 0) {
      return defaultValue;
    }

    const langs = texts.value.reduce((acc, text) => {
      const lang = Object.keys(text)
        .filter(textKey => fields.includes(textKey))
        .filter(textKey => text[textKey])
        .map(() => text?.language);

      return [...acc, ...[...new Set(lang)]];
    }, []);

    if (langs.length === 1) {
      return langs[0];
    }

    return defaultValue;
  };

  // Data
  const lang = ref("");

  // Provide
  provide("lang", lang);

  // Watch
  watch(
    () => texts,
    () => {
      lang.value = getLanguage(texts, localizedFields);
    }
  );

  return {
    lang,
    updateLang
  };
}
