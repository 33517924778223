import { useRouter } from "vue-router";
// Composables
import useRequest from "@/composables/useRequest";

export default function useCreate(endpoint, route) {
  // MISC
  const router = useRouter();

  // Composables
  const { isRequesting: isLoading, request } = useRequest();

  // METHODS
  const onClickSave = async data => {
    if (!data) {
      return;
    }

    if ("colour" in data && !data.colour) {
      delete data.colour;
    }

    const response = await request({
      endpoint: `${endpoint}.create`,
      data
    });

    if (response?.payload?.message?.status === "Success") {
      router.push({
        name: `${route}-details`,
        params: {
          id: response.payload?.data?.id
        }
      });
    }
  };

  const onClickCancel = () => {
    router.push({ name: route });
  };

  return {
    isLoading,
    onClickSave,
    onClickCancel
  };
}
