<template>
  <div
    class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
    role="tablist"
  >
    <VTab
      v-for="(tab, index) in tabs"
      :key="tab"
      :selected-tab="currentTab"
      :label="tab.label"
      :name="tab.name"
      :icon="tab.icon"
      :disabled="tab.disabled"
      :class="{ 'end-tab': index === tabs.length - 1 }"
      @click="onClickUpdateTab(tab)"
    />
  </div>
</template>

<script>
import VTab from "@/components/VTab";

export default {
  components: {
    VTab
  },
  props: {
    currentTab: {
      type: String,
      default: ""
    },
    tabs: {
      type: Array,
      default: () => [],
      validator: tabs =>
        tabs.every(tab => {
          const properties = ["name", "label", "icon", "disabled"];

          return properties.every(property => property in tab);
        })
    }
  },
  emits: ["click"],
  setup(props, context) {
    const onClickUpdateTab = updatedTab => {
      if (updatedTab.disabled) {
        return;
      }

      context.emit("click", updatedTab.name);
    };

    return {
      onClickUpdateTab
    };
  }
};
</script>
