<template>
  <div>
    <label v-if="label" :for="id" class="form-label font-medium">
      {{ label }}
    </label>

    <div class="flex">
      <div class="input-group mr-2">
        <VSelect
          v-model="option"
          :readonly="readonly"
          :placeholder="$t('app.search')"
          :load="onLoad"
          class="w-96"
          is-fetched
          @update:modelValue="onUpdateOption"
        />

        <div class="input-group-text">
          <i class="fal fa-search" />
        </div>
      </div>

      <VButton
        :disabled="readonly || disabled"
        :is-loading="isAdding"
        icon="fa-plus"
        class="btn-primary"
        @click="onClickAdd"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
// Components
import VSelect from "@/components/inputs/VSelect";
import VButton from "@/components/VButton";
// Mixins
import InputMixin from "@/mixins/InputMixin";

export default {
  components: {
    VButton,
    VSelect
  },
  mixins: [InputMixin],
  props: {
    isAdding: {
      type: Boolean,
      default: false
    },
    load: {
      type: Function,
      default: () => {}
    },
    formatOptions: {
      type: Function,
      default: () => {}
    }
  },
  emits: ["add"],
  setup(props, context) {
    // Data
    const option = ref("");
    const options = ref([]);

    // Methods
    const onLoad = async (query, callback) => {
      const response = await props.load(query);
      const results = props.formatOptions(response?.payload?.data ?? []);

      options.value = results;
      callback(results);
    };

    const onClickAdd = () => {
      context.emit("add", option.value);
      option.value = "";
    };

    const onUpdateOption = id => {
      const foundOption = options.value.find(x => `${x.id}` === id);
      context.emit("selected:option", foundOption);
    };

    return {
      option,
      onLoad,
      onClickAdd,
      onUpdateOption
    };
  }
};
</script>
