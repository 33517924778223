<template>
  <div>
    <slot name="label">
      <label v-if="label" :for="id" class="form-label">
        {{ label }}
      </label>
    </slot>

    <div class="input-group">
      <div v-if="inputGroup" class="input-group-text">
        {{ inputGroup }}
      </div>

      <CKEditor
        :model-value="modelValue ?? ''"
        :editor="classicEditor"
        :config="editorConfig"
        :disabled="readonly"
        :readonly="readonly"
        class="wysiwyg"
        :class="[{ 'has-errors': hasErrors }]"
        @update:modelValue="$emit('update:modelValue', $event)"
        @blur="$emit('blur')"
      />
    </div>

    <VErrors :errors="errors" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import StrikethroughPlugin from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import CodePlugin from "@ckeditor/ckeditor5-basic-styles/src/code";
import SubscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import SuperscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import EasyImagePlugin from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload";
import CloudServicesPlugin from "@ckeditor/ckeditor5-cloud-services/src/cloudservices";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import HeadingButtonsUI from "@ckeditor/ckeditor5-heading/src/headingbuttonsui";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
import InputMixin from "@/mixins/InputMixin";

export default defineComponent({
  mixins: [InputMixin],
  emits: ["blur"],
  setup() {
    const classicEditor = ClassicEditor;
    const editorConfig = {
      cloudServices: {
        tokenUrl: "",
        uploadUrl: ""
      },
      plugins: [
        Font,
        EssentialsPlugin,
        BoldPlugin,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImagePlugin,
        ImagePlugin,
        ImageUploadPlugin,
        CloudServicesPlugin,
        Heading,
        HeadingButtonsUI,
        Highlight
      ],
      toolbar: {
        items: [
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "code",
          "subscript",
          "superscript",
          "link",
          "undo",
          "redo",
          "imageUpload",
          "highlight"
        ]
      }
    };

    const initDocumentEditor = editor => {
      const toolbarContainer = document.querySelector(
        ".document-editor__toolbar"
      );
      toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
      const win = window;
      win.editor = editor;
    };

    return {
      classicEditor,
      editorConfig,
      initDocumentEditor
    };
  }
});
</script>

<style scoped lang="scss">
.has-errors ~ :deep(.ck-editor .ck-editor__main .ck-content) {
  @apply border-theme-6;
}

.wysiwyg ~ :deep(.ck.ck-editor) {
  @apply w-full;
}

.wysiwyg ~ :deep(.ck.ck-editor .ck-editor__main .ck-content) {
  min-height: 60px;
  padding: 8px 12px;
  @apply shadow-none;

  & p {
    @apply mt-0 mb-0;
  }
}

.wysiwyg ~ :deep(.ck.ck-editor .ck-editor__main .ck-content.ck-read-only) {
  background-color: rgba(247, 250, 252, 1);
  opacity: 1;
  cursor: not-allowed;
}
</style>
