<template>
  <div class="grid grid-cols-2 gap-x-8 gap-y-6">
    <LocalizedInput
      component="VText"
      :model-value="value.name"
      :errors="v.name"
      :label="$t('app.name')"
      :lang="lang"
      :readonly="readonly || locked"
      field="name"
      class="col-span-2"
      @update:modelValue="onUpdate"
      @keyup.enter="onSave"
    />

    <LocalizedInput
      component="VWysiwyg"
      :model-value="value.details"
      :errors="v.details"
      :label="$t('app.details')"
      :lang="lang"
      class="col-span-2"
      field="details"
      :readonly="readonly || locked"
      @update:modelValue="onUpdate"
    />

    <VSelect
      v-model="v.department_id.$model"
      :label="$t('app.department_id')"
      :errors="v.department_id.$errors"
      :options="options.department_id"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VSelect
      v-model="v.mandatory_for.$model"
      :label="$t('app.mandatory_for')"
      :errors="v.mandatory_for.$errors"
      :options="options.mandatory_for"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    >
      <template #hint>
        {{ $t("hints.teaching_trainings_mandatory_for") }}
      </template>
    </VSelect>

    <VText
      v-model="v.version.$model"
      :label="$t('app.version')"
      :errors="v.version.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.code.$model"
      :label="$t('app.code')"
      :errors="v.code.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    >
      <template #hint>
        {{ $t("hints.teaching_trainings_code") }}
      </template>
    </VText>

    <VText
      v-model="v.author.$model"
      :label="$t('app.author')"
      :errors="v.author.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.available_registrations.$model"
      :label="$t('app.available_registrations')"
      :placeholder="
        v.available_registrations.$model === null ? $t('app.unlimited') : ''
      "
      type="number"
      :errors="v.available_registrations.$errors"
      :readonly="readonly || locked"
      @update:modelValue="onUpdateAvailableRegistrations"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.duration.$model"
      :label="$t('app.duration')"
      type="number"
      :errors="v.duration.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.credits.$model"
      :label="$t('app.credits')"
      type="number"
      :errors="v.credits.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.price.$model"
      :label="$t('app.price')"
      type="number"
      :errors="v.price.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.completion_deadline_days.$model"
      :label="$t('app.completion_deadline_days')"
      type="number"
      :errors="v.completion_deadline_days.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.completion_deadline_days_new_learners.$model"
      :label="$t('app.completion_deadline_days_new_learners')"
      type="number"
      :errors="v.completion_deadline_days_new_learners.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VSelect
      v-model="v.certificate_template.$model"
      :label="$t('app.certificate_template')"
      :errors="v.certificate_template.$errors"
      :options="options.certificate_template"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VDatePicker
      v-model="v.available_from.$model"
      :label="$t('app.available_from')"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VDatePicker
      v-model="v.available_until.$model"
      :label="$t('app.available_until')"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VSelect
      v-model="v.available_to.$model"
      :label="$t('app.available_to')"
      :errors="v.available_to.$errors"
      :options="options.available_to"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    >
      <template #hint>
        {{ $t("hints.teaching_trainings_available_to") }}
      </template>
    </VSelect>

    <VSwitch
      v-model="v.requires_registration_approval.$model"
      :label="$t('app.requires_registration_approval')"
      true-value="Yes"
      false-value="No"
      :readonly="readonly || locked"
      :errors="v.requires_registration_approval.$errors"
      @keyup.enter="onSave"
    />

    <VSelect
      v-model="v.duration_type.$model"
      :label="$t('app.duration_type')"
      :errors="v.duration_type.$errors"
      :options="options.duration_type"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VSwitch
      v-model="v.requires_completion_approval.$model"
      :label="$t('app.requires_completion_approval')"
      true-value="Yes"
      false-value="No"
      :readonly="readonly || locked"
      :errors="v.requires_completion_approval.$errors"
      @keyup.enter="onSave"
    />

    <VSelect
      v-model="v.status.$model"
      :label="$t('app.status')"
      :errors="v.status.$errors"
      :options="options.status"
      :readonly="locked"
      @keyup.enter="onSave"
    />

    <VSwitch
      v-model="v.sys_admin_locked.$model"
      :disabled="!isSysAdmin"
      :label="$t('app.sys_admin_locked')"
      true-value="Yes"
      false-value="No"
      :readonly="readonly || (!isSysAdmin && locked)"
      :errors="v.sys_admin_locked.$errors"
      @keyup.enter="onSave"
    />

    <VDatePicker
      v-if="!isCreate"
      v-model="v.date_activated.$model"
      readonly
      :errors="v.date_activated.$errors"
      :label="$t('app.date_activated')"
    />

    <VDatePicker
      v-if="!isCreate"
      v-model="v.date_archived.$model"
      readonly
      :errors="v.date_archived.$errors"
      :label="$t('app.date_archived')"
    />

    <VSelect
      v-model="v.assign_competencies_upon.$model"
      :readonly="!hasRequireApproval"
      :label="$t('app.assign_competencies_upon')"
      :errors="v.assign_competencies_upon.$errors"
      :options="options.assign_competencies_upon"
      @keyup.enter="onSave"
    >
      <template #hint>
        {{ $t("hints.teaching_trainings_assign_competencies_upon") }}
      </template>
    </VSelect>

    <VFile
      v-model="v.image_file_id.$model"
      :label="$t('app.image_file_id')"
      :options="options.image_file_id"
      accept="image/*"
      :readonly="readonly || locked"
      :errors="v.image_file_id.$errors"
    />

    <VTags
      v-model="v.tags.$model"
      :label="$t('app.tags')"
      :readonly="locked"
      :errors="v.tags.$errors"
    />
  </div>
</template>

<script>
import { onMounted, computed, inject } from "vue";
// Composables
import useOptions from "@/composables/useOptions";
import useTraining from "@/composables/useTraining";
import useForm from "@/composables/useForm";
// Mixins
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  emits: ["click:save"],
  setup(props, context) {
    // Inject
    const lang = inject("lang");

    // Composables
    const { ALL_OPTIONS } = useOptions();
    const {
      value,
      rules,
      options,
      localizedFields,
      relationFields,
      getOptions
    } = useTraining();
    const {
      v,
      onUpdate,
      onSave,
      isSysAdmin,
      isActive,
      locked,
      isCreate
    } = useForm(props, context, {
      value,
      rules,
      localizedFields,
      options,
      relationFields,
      lang
    });

    // Computed
    const statusOptions = computed(() => {
      return options.value.status.map(option => {
        const underReview = ALL_OPTIONS.UNDER_REVIEW.value;
        const isOptionUnderReview = underReview === option.value;

        return {
          ...option,
          disabled: isOptionUnderReview && isActive.value
        };
      });
    });

    const hasRequireApproval = computed(() => {
      // eslint-disable-next-line
      return props.resource?.requires_completion_approval === ALL_OPTIONS.YES.value;
    });

    // Methods
    const onUpdateAvailableRegistrations = available_registrations => {
      if (available_registrations === "") {
        v.value.available_registrations.$model = null;
      }
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getOptions();
    });

    return {
      onUpdateAvailableRegistrations,
      statusOptions,
      hasRequireApproval,
      lang,
      // useTraining
      value,
      options,
      // useForm
      isCreate,
      v,
      onUpdate,
      locked,
      isSysAdmin,
      onSave
    };
  }
};
</script>
