import { ref } from "vue";
import { required } from "@/i18n/i18n-validators";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";
// Constants
import inputTypes from "@/constants/inputTypes";

export default function useEvaluation() {
  // Misc
  const { t } = useI18n();

  const { ALL_OPTIONS, defaultSystemOptions } = useOptions();

  // CONSTANTS
  const endpoint = "teaching.evaluations";
  const searchFields = ["name"];
  const localizedFields = ["name", "details"];
  const types = {
    name: inputTypes.TEXT,
    details: inputTypes.TEXT,
    status: inputTypes.SELECT,
    colour: inputTypes.COLOR,
    version: inputTypes.TEXT,
    passing_grade: inputTypes.NUMBER,
    display_correct_answers: inputTypes.SELECT,
    maximum_attempts_per_registration: inputTypes.NUMBER,
    default_answer_type: inputTypes.SELECT,
    number_of_reevaluations_if_failed: inputTypes.NUMBER,
    attempt_interval_in_days: inputTypes.NUMBER,
    requires_approval: inputTypes.SELECT,
    assign_competencies_upon: inputTypes.SELECT,
    sys_admin_locked: inputTypes.SELECT,
    randomize_question_order: inputTypes.SELECT,
    randomize_answer_order: inputTypes.SELECT,
    tags: inputTypes.TAGS,
    date_activated: inputTypes.DATE,
    date_archived: inputTypes.DATE
  };
  const headers = [
    {
      value: "image",
      class: "w-10"
    },
    {
      text: t("app.name"),
      value: "name"
    },
    {
      text: t("app.version"),
      value: "version"
    },
    {
      text: t("app.status"),
      value: "status",
      class: "w-40"
    },
    {
      text: t("app.actions"),
      value: "__ACTIONS",
      class: "w-56"
    }
  ];

  // DATA
  const value = ref({
    colour: "",
    version: "1.0",
    passing_grade: 60,
    display_answers: ALL_OPTIONS.YES.value,
    display_correct_answers: ALL_OPTIONS.YES.value,
    maximum_attempts_per_registration: 1,
    number_of_reevaluations_if_failed: 1,
    attempt_interval_in_days: 0,
    requires_approval: ALL_OPTIONS.YES.value,
    assign_competencies_upon: ALL_OPTIONS.LEARNER_COMPLETION.value,
    sys_admin_locked: ALL_OPTIONS.NO.value,
    name: "",
    details: "",
    status: ALL_OPTIONS.UNDER_REVIEW.value,
    randomize_question_order: ALL_OPTIONS.YES.value,
    tags: "",
    date_activated: "",
    date_archived: ""
  });
  const rules = ref({
    colour: {},
    type: { required },
    version: { required },
    duration: {},
    duration_type: {},
    passing_grade: {},
    display_answers: { required },
    display_correct_answers: { required },
    maximum_attempts_per_registration: {},
    attempt_interval_in_days: {},
    default_answer_type: {},
    number_of_reevaluations_if_failed: {},
    requires_approval: { required },
    assign_competencies_upon: { required },
    sys_admin_locked: { required },
    name: { required },
    details: {},
    status: { required },
    randomize_question_order: { required },
    randomize_answer_order: { required },
    tags: {},
    date_activated: {},
    date_archived: {}
  });
  const options = ref({
    duration_type: [ALL_OPTIONS.HOURS, ALL_OPTIONS.MINUTES],
    display_answers: defaultSystemOptions,
    display_correct_answers: defaultSystemOptions,
    requires_approval: defaultSystemOptions,
    sys_admin_locked: defaultSystemOptions,
    status: [
      ALL_OPTIONS.ACTIVE,
      ALL_OPTIONS.ARCHIVED,
      ALL_OPTIONS.UNDER_REVIEW
    ],
    randomize_question_order: defaultSystemOptions,
    randomize_answer_order: defaultSystemOptions,
    type: [ALL_OPTIONS.QUIZ, ALL_OPTIONS.DEMO],
    assign_competencies_upon: [
      ALL_OPTIONS.LEARNER_COMPLETION,
      ALL_OPTIONS.COMPLETION_APPROVAL
    ]
  });

  return {
    headers,
    types,
    endpoint,
    searchFields,
    value,
    rules,
    options,
    localizedFields
  };
}
