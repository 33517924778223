<template>
  <div>
    <div class="flex items-center justify-between mb-10">
      <div class="text-base font-medium">
        {{ $t("app.manage_instructors") }}
      </div>

      <VSearchResource
        :load="load"
        :format-options="formatLoadOptions"
        @add="onClickAddUser"
      />
    </div>

    <VTable :headers="headers" :rows="instructors">
      <!-- eslint-disable-next-line -->
      <template #item.__ACTIONS="{ item }">
        <div class="flex justify-end">
          <VDeleteAction
            :item="item"
            :text-value="`${item.firstname} ${item.lastname}`"
            :delete-function="onClickDelete"
          />
        </div>
      </template>
    </VTable>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
import useOptions from "@/composables/useOptions";
import useUser from "@/composables/useUser";
// Components
import VTable from "@/components/tables/VTable";
import VDeleteAction from "@/components/VDeleteAction";
import VSearchResource from "@/components/inputs/VSearchResource";
// Constants
import systemRoles from "@/constants/systemRoles";

export default {
  components: {
    VTable,
    VSearchResource,
    VDeleteAction
  },
  props: {
    id: {
      type: [Number, String],
      default: ""
    },
    instructors: {
      type: Array,
      default: () => []
    },
    endpoint: {
      type: String,
      required: true
    }
  },
  emits: ["add:instructor", "remove:instructor"],
  setup(props, context) {
    // Misc
    const { t } = useI18n();

    // Constants
    const headers = [
      {
        text: t("app.firstname"),
        value: "firstname"
      },
      {
        text: t("app.lastname"),
        value: "lastname"
      },
      {
        text: t("app.username"),
        value: "username"
      },
      {
        value: "__ACTIONS"
      }
    ];

    // Computed
    const instructorIds = computed(() => {
      return props.instructors.map(instructor => instructor.id);
    });

    // Composables
    const { isRequesting: isAdding, request: update } = useRequest();
    const { request } = useRequest();
    const { endpoint, searchFields } = useUser();
    const { ALL_OPTIONS, loadOptions, formatOptions } = useOptions();

    // Methods
    const load = query => {
      const filters = [
        {
          f: searchFields,
          c: "LIKE",
          v: [query]
        },
        {
          f: "status",
          c: "EQUAL",
          v: [ALL_OPTIONS.ACTIVE.value]
        }
      ];

      return loadOptions(endpoint, {
        ro_f: encodeURIComponent(JSON.stringify(filters)),
        role_code: systemRoles.INSTRUCTOR
      });
    };

    const formatLoadOptions = options => {
      const formatText = option => `${option.firstname} ${option.lastname}`;
      const formattedOptions = formatOptions(options, { formatText });

      return formattedOptions.map(option => ({
        ...option,
        disabled: instructorIds.value.includes(option.id)
      }));
    };

    const onClickAddUser = async id => {
      await update({
        endpoint: `${props.endpoint}.update`,
        pathParams: {
          id: props.id
        },
        data: {
          instructors: JSON.stringify([...instructorIds.value, id])
        }
      });

      context.emit("add:instructor");
    };

    const onClickDelete = async item => {
      await request({
        endpoint: `${props.endpoint}.instructors.delete`,
        pathParams: {
          id: props.id,
          instructor_id: item.id
        }
      });

      context.emit("remove:instructor");
    };

    return {
      headers,
      load,
      onClickAddUser,
      onClickDelete,
      formatLoadOptions,
      // useRequest
      isAdding
    };
  }
};
</script>
