import { ref } from "vue";
// Composables
import useOptions from "@/composables/useOptions";
import useEvaluation from "./useEvaluation";
// Constants
import inputTypes from "@/constants/inputTypes";

export default function useQuiz() {
  const { ALL_OPTIONS } = useOptions();
  const {
    value: evaluationValue,
    rules,
    options: evaluationOptions,
    types: evaluationTypes,
    localizedFields,
    searchFields,
    endpoint,
    headers
  } = useEvaluation();

  const types = {
    ...evaluationTypes,
    duration: inputTypes.NUMBER,
    duration_type: inputTypes.SELECT
  };

  // CONSTANTS
  const view_code = "Quizzes";
  const route = "teaching-evaluations-quizzes";
  const filters = [
    {
      f: "type",
      c: "EQUAL",
      v: [ALL_OPTIONS.QUIZ.value]
    }
  ];

  // DATA
  const value = ref({
    ...evaluationValue.value,
    duration: 60,
    duration_type: ALL_OPTIONS.MINUTES.value,
    type: ALL_OPTIONS.QUIZ.value,
    default_answer_type: ALL_OPTIONS.TRUE_FALSE.value,
    randomize_answer_order: ALL_OPTIONS.YES.value
  });
  const options = ref({
    ...evaluationOptions.value,
    default_answer_type: [
      ALL_OPTIONS.TRUE_FALSE,
      ALL_OPTIONS.MULTIPLE_CHOICE,
      ALL_OPTIONS.SINGLE_CHOICE
    ]
  });

  return {
    headers,
    filters,
    view_code,
    types,
    route,
    endpoint,
    searchFields,
    value,
    rules,
    options,
    localizedFields
  };
}
