import { ref } from "vue";
// Composables
import useOptions from "@/composables/useOptions";
import useEvaluation from "./useEvaluation";

export default function useObservation() {
  const { ALL_OPTIONS } = useOptions();
  const {
    value: evaluationValue,
    rules,
    options: evaluationOptions,
    types,
    localizedFields,
    searchFields,
    endpoint,
    headers
  } = useEvaluation();

  // CONSTANTS
  const view_code = "Observations";
  const route = "teaching-evaluations-observations";
  const filters = [
    {
      f: "type",
      c: "EQUAL",
      v: [ALL_OPTIONS.OBSERVATION.value]
    }
  ];

  // DATA
  const value = ref({
    ...evaluationValue.value,
    type: ALL_OPTIONS.OBSERVATION.value,
    default_answer_type: ALL_OPTIONS.THREE_CHOICES.value,
    randomize_answer_order: ALL_OPTIONS.NO.value
  });
  const options = ref({
    ...evaluationOptions.value,
    default_answer_type: [ALL_OPTIONS.THREE_CHOICES, ALL_OPTIONS.FOUR_CHOICES]
  });

  return {
    headers,
    filters,
    view_code,
    types,
    route,
    endpoint,
    searchFields,
    value,
    rules,
    options,
    localizedFields
  };
}
