<template>
  <div>
    <slot name="label">
      <label v-if="label" class="form-label">
        {{ label }}
      </label>
    </slot>

    <div class="input-group relative w-full">
      <div v-if="inputGroup" class="input-group-text">
        {{ inputGroup }}
      </div>

      <div
        v-if="!inputGroup"
        class="input-group-text cursor-pointer"
        @click="onClickBrowseFiles"
      >
        <i class="fal fa-paperclip fa-lg" />
      </div>

      <VText :model-value="modelValue" class="w-full" disabled />

      <div
        v-if="inputGroup"
        class="input-group-text cursor-pointer"
        @click="onClickBrowseFiles"
      >
        <i class="fal fa-paperclip fa-lg" />
      </div>
    </div>

    <ProgressBar
      v-if="percentage > 0"
      class="mt-1"
      :percentage="percentage"
      hide-percentage
    />
  </div>
</template>

<script>
import * as tus from "tus-js-client";
import { ref, computed } from "vue";
import { useStore } from "@/store";
// Composables
import useRequest from "@/composables/useRequest";
// Components
import ProgressBar from "@/components/ProgressBar";
import VText from "@/components/inputs/VText";

export default {
  components: {
    ProgressBar,
    VText
  },
  props: {
    modelValue: {
      type: [String, Number, Array],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    inputGroup: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: "*"
    }
  },
  setup(props, context) {
    // Misc
    const store = useStore();

    // Data
    const percentage = ref(0);

    // Composables
    const { request } = useRequest();

    // Computed
    const maxUploadSize = computed(() => {
      return store.getters["app/max_upload_size"];
    });

    // Methods
    const browseFiles = () => {
      if (props.readonly) {
        return;
      }

      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", props.accept);
      input.click();
      input.addEventListener("input", uploadFile);
      return false;
    };

    const onClickBrowseFiles = () => {
      browseFiles();
    };

    const uploadFile = async e => {
      const file = getRightSizeFiles(e);

      if (!file) {
        return;
      }

      const { name, size } = file ?? {};
      const response = await getVideoUploadURL({ name, details: "", size });
      const { upload_url, link } = response?.payload?.data ?? {};
      await uploadVideo(file, upload_url, link);
    };

    const getVideoUploadURL = async data => {
      return await request({
        endpoint: "teaching.lessons.video-upload-url",
        data
      });
    };

    const uploadVideo = async (file, url, link) => {
      if (!file || !url || !link) {
        return;
      }

      const upload = new tus.Upload(file, {
        uploadUrl: url,
        onProgress: function(bytesUploaded, bytesTotal) {
          percentage.value = (bytesUploaded / bytesTotal) * 100;
        },
        onSuccess: function() {
          context.emit("update:modelValue", link);
        }
      });

      upload.start();
    };

    const getRightSizeFiles = e => {
      const file = e.path[0].files[0];
      const fileSize = (file.size / 1024 / 1024).toFixed(4);

      if (fileSize > maxUploadSize) {
        return;
      }

      return file;
    };

    return {
      percentage,
      onClickBrowseFiles
    };
  }
};
</script>
