<template>
  <VCard>
    <template v-if="$slots.tabs" #tabs>
      <slot name="tabs" />
    </template>

    <template v-if="$slots.title" #title>
      <slot name="title" />
    </template>

    <template v-if="$slots['title-actions']" #title-actions>
      <slot name="title-actions" />
    </template>

    <template #content>
      <slot name="content" />
    </template>

    <template v-if="!hideActions" #actions>
      <slot name="actions">
        <div class="text-right">
          <VButton
            :disabled="readonly"
            data-test="cancel"
            class="btn-outline-secondary mr-1"
            :label="$t('app.cancel')"
            @click="$emit('click:cancel')"
          />

          <VButton
            :disabled="readonly"
            data-test="save"
            class="btn-primary"
            :label="$t('app.save')"
            :is-loading="isLoading"
            @click="$emit('click:save')"
          />
        </div>
      </slot>
    </template>
  </VCard>
</template>

<script>
import VButton from "@/components/VButton.vue";
import VCard from "@/components/VCard.vue";

export default {
  components: {
    VButton,
    VCard
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click:cancel", "click:save"]
};
</script>
