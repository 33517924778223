<!-- eslint-disable -->
<template>
  <div>
    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert
      v-else-if="formattedData.length === 0"
      :text="$t('app.no_outline')"
    />

    <div v-else>
      <div class="flex justify-end mb-4 -mt-4">
        <VButton
          :icon="`fal fa-chevron-double-${isCollapsedAll ? 'up' : 'down'}`"
          :label="isCollapsedAll ? $t('app.expand_all') : $t('app.collapse_all')"
          class="btn-sm btn-outline-primary bg-white"
          @click="isCollapsedAll = !isCollapsedAll"
        />
      </div>

      <VDraggable :value="formattedData" @change="onReorder">
        <template
          v-for="outline in formattedData"
          :key="outline.id"
        >
          <component
            :is="outline.outline_object_type"
            :id="id"
            :resource="outline"
            :collapsed-all="isCollapsedAll"
            :readonly="readonly"
            class="mb-8"
            @refresh="getData"
          />
        </template>
      </VDraggable>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// Composables
import useRequest from "@/composables/useRequest";
import useTraining from "@/composables/useTraining";
import useTexts from "@/composables/useTexts";
// Components
import VCard from "@/components/VCard";
import VDeleteAction from "@/components/VDeleteAction";
import VDrag from "@/components/VDrag";
import VButton from "@/components/VButton";
import VDraggable from "@/components/VDraggable";
import Lesson from "./Lesson";
import Attestation from "./Attestation";
import Evaluation from "./Evaluation";
import VAlert from "@/components/VAlert";

export default {
  components: {
    VButton,
    VCard,
    VDeleteAction,
    VDrag,
    VDraggable,
    Evaluation,
    Lesson,
    Attestation,
    VAlert
  },
  props: {
    // trainingId
    id: {
      type: [Number, String],
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Composables
    const { getText } = useTexts();
    const { endpoint } = useTraining();
    const { request, isRequesting: isLoading } = useRequest();
    const { request: reorder } = useRequest();

    // Data
    const data = ref([]);
    const isCollapsedAll = ref(false);

    // Computed
    const formattedData = computed(() => {
      return data.value.map(x => ({
        ...x,
        id: `${x.id}-${x.outline_object_type}`,
        original_id: x.id
      }));
    });

    // Methods
    const getData = async () => {
      const response = await request({
        endpoint: `${endpoint}.outline`,
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? [];
    };

    const onReorder = async values => {
      const new_order = values.map((value, index) => ({
        [index]: {
          type: value.outline_object_type,
          id: value.original_id
        }
      }));

      await reorder({
        endpoint: "teaching.trainings.reorder-outline",
        pathParams: {
          id: props.id
        },
        data: {
          new_order: JSON.stringify(new_order)
        },
        showToaster: false
      });

      await getData();
    };

    const addOutline = async (id, attribute) => {
      const types = {
        lessons: "Lesson",
        evaluations: "Evaluation",
        attestations: "Attestation"
      };

      const ids = data.value
        .filter(x => x.outline_object_type === types[attribute])
        .map(x => x.id);

      await request({
        endpoint: `${endpoint}.update`,
        pathParams: {
          id: props.id
        },
        data: {
          [attribute]: JSON.stringify([...ids, id])
        }
      });

      await getData();
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      addOutline,
      formattedData,
      isCollapsedAll,
      onReorder,
      getData,
      isLoading,
      getText,
      data
    };
  }
};
</script>
