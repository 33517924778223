import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";

export default function useTabs(defaultValue) {
  // Misc
  const { t } = useI18n();
  const store = useStore();

  // DATA
  const currentTab = ref(defaultValue);

  // METHODS
  const updateTab = updatedTab => {
    currentTab.value = updatedTab;
  };

  const isCurrentTab = tab => currentTab.value === tab;

  const buildTab = args => {
    let { name, label, icon = "", disabled = false, hideIcon = false } = args;

    if (!label) {
      label = t(`app.${name}`, 2);
    }

    if (!icon && !hideIcon) {
      const storeIcon = store.getters["icons/getIcon"](name);
      icon = storeIcon ? `${storeIcon} mr-2` : "";
    }

    return {
      name,
      label,
      icon,
      disabled
    };
  };

  return {
    buildTab,
    currentTab,
    updateTab,
    isCurrentTab
  };
}
