<template>
  <div>
    <FormCard
      :is-loading="isLoading"
      :readonly="isCurrentTab(TABS.COMPETENCIES) && isActive"
      :hide-actions="!hasActions"
      @click:cancel="onCancel"
      @click:save="onSaveForm"
    >
      <template #tabs>
        <VTabs
          :current-tab="currentTab"
          :tabs="tabs"
          @click="onClickUpdateTab"
        />
      </template>

      <template #content>
        <Details
          v-if="isCurrentTab(TABS.DETAILS)"
          ref="details"
          :readonly="isActive"
          :data="data"
          @click:save="$emit('click:save', $event)"
        />

        <OutlineSearch
          v-if="isCurrentTab(TABS.OUTLINE)"
          :id="id"
          :readonly="isActive"
          :resource="data"
          @add="addOutline"
        />

        <Instructors
          v-if="isCurrentTab(TABS.INSTRUCTORS)"
          :id="id"
          :instructors="data.instructors"
          :endpoint="endpoint"
          @add:instructor="refresh"
          @remove:instructor="refresh"
        />

        <Roles
          v-if="isCurrentTab(TABS.ROLES)"
          :id="id"
          :roles="data.roles"
          :endpoint="endpoint"
          @add:role="refresh"
          @remove:role="refresh"
        />

        <Prerequisites
          v-if="isCurrentTab(TABS.PREREQUISITES)"
          :id="id"
          :prerequisites="data.prerequisites"
          :readonly="isActive"
          :endpoint="endpoint"
          @add="refresh"
          @remove="refresh"
        />

        <Competencies
          v-if="isCurrentTab(TABS.COMPETENCIES)"
          :id="id"
          ref="competencies"
          :readonly="isActive"
          :competencies="data.competencies"
          :endpoint="endpoint"
          @add:competency="refresh"
          @remove:competency="refresh"
        />
      </template>
    </FormCard>

    <Outline
      v-if="isCurrentTab(TABS.OUTLINE)"
      :id="id"
      ref="outline"
      :readonly="isActive"
      class="mt-8"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
// Composables
import useForm from "@/composables/useForm";
import useTabs from "@/composables/useTabs";
import useTraining from "@/composables/useTraining";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";
// Components
import VTabs from "@/components/VTabs";
import FormCard from "@/components/FormCard";
import Details from "./forms/Details";
import Outline from "./forms/Outline";
import OutlineSearch from "./forms/OutlineSearch";
import Instructors from "@/components/templates/Instructors";
import Competencies from "@/components/templates/Competencies";
import Prerequisites from "@/components/templates/Prerequisites";
import Roles from "@/components/templates/Roles";

export default {
  components: {
    VTabs,
    Details,
    Instructors,
    Competencies,
    FormCard,
    Roles,
    Outline,
    OutlineSearch,
    Prerequisites
  },
  mixins: [FormMixin],
  props: {
    id: {
      type: [String, Number],
      default: ""
    }
  },
  emits: ["update:tab", "click:save", "refresh"],
  setup(props, context) {
    // Constants
    const TABS = {
      DETAILS: "details",
      OUTLINE: "outline",
      INSTRUCTORS: "instructors",
      ROLES: "roles",
      COMPETENCIES: "competencies",
      PREREQUISITES: "prerequisites"
    };

    // Composables
    // eslint-disable-next-line
    const { buildTab, currentTab, updateTab, isCurrentTab } = useTabs(TABS.DETAILS);
    const { endpoint } = useTraining();
    const { isActive, isCreate, onCancel } = useForm(props, context);

    // Data
    const details = ref(null);
    const competencies = ref(null);
    const outline = ref(null);

    // Computed
    const tabs = computed(() => {
      return Object.values(TABS).map(tab => {
        return buildTab({
          name: tab,
          hideIcon: true,
          disabled: tab !== TABS.DETAILS && isCreate.value
        });
      });
    });

    const hasActions = computed(() => {
      return isCurrentTab(TABS.DETAILS) || isCurrentTab(TABS.COMPETENCIES);
    });

    // Methods
    const onSaveForm = async () => {
      switch (currentTab.value) {
        case TABS.DETAILS:
          await details.value.onSave();
          break;
        case TABS.COMPETENCIES:
          await competencies.value.onSave();
          break;
        default:
      }
    };

    const onClickUpdateTab = tab => {
      updateTab(tab);
      context.emit("update:tab", currentTab.value);
    };

    const refresh = () => {
      context.emit("refresh");
    };

    const addOutline = (id, attribute) => {
      outline.value.addOutline(id, attribute);
    };

    return {
      TABS,
      isActive,
      addOutline,
      outline,
      refresh,
      hasActions,
      endpoint,
      tabs,
      details,
      competencies,
      onSaveForm,
      onClickUpdateTab,
      // useForm
      onCancel,
      // useTabs
      currentTab,
      isCurrentTab
    };
  }
};
</script>
