<!-- eslint-disable -->
<template>
  <VCard :collapsed="isCollapsed" title-class="bg-theme-5">
    <template #title>
      <div class="flex items-center">
        <VImage
          :color="resource.colour"
          :name="getText(resource.texts, 'name')"
          class="mr-4"
        />

        <div>
          <div class="label text-xs font-light uppercase">
            {{ $t(`backend.${resource.outline_object_type}`) }}
          </div>

          <div>
            {{ getText(resource?.texts, "name") }}
          </div>
        </div>
      </div>
    </template>

    <template #title-actions>
      <div class="flex items-center">
        <VDrag :readonly="readonly" />

        <VDeleteAction
          :item="resource"
          :disabled="readonly"
          :text-value="getText(resource?.texts, 'name')"
          :delete-function="onClickDelete"
        />

        <VAction
          :icon="isCollapsed ? 'expand' : 'collapse'"
          :label="$t(`app.${isCollapsed ? 'expand' : 'collapse'}`)"
          @click="onClickToggle"
        />

        <VAction
          icon="redirect"
          :label="$t('app.redirect')"
          @click="onClickRedirect"
        />
      </div>
    </template>

    <template #content>
      <div class="version">
        {{ $t("app.versions") }} {{ resource?.version }}
      </div>

      <div class="mb-4">
        {{ trimHTML(getText(resource?.texts, "details")) }}
      </div>

      <div class="grid gap-2">
        <VLine
          :label="$t('app.type')"
          :value="resource.reevaluations_interval_in_days"
        >
          <template #value>
            <div class="lesson-type">
              {{ resource.type }}
            </div>
          </template>
        </VLine>

        <VLine
          :label="$t('app.duration')"
          :value="`${resource?.duration} ${lowercase($t(`app.${resource?.duration_type}`))}`"
        />

        <VLine
          :label="$t('app.languages', 2)"
          :value="languages"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { ref, watch, computed } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
// Composables
import useColor from "@/composables/useColor";
import useLesson from "@/composables/useLesson";
import useRequest from "@/composables/useRequest";
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
// Components
import VCard from "@/components/VCard";
import VDeleteAction from "@/components/VDeleteAction";
import VAction from "@/components/tables/VAction";
import VDrag from "@/components/VDrag";
import VImage from "@/components/VImage";
import VLine from "@/components/VLine";

export default {
  components: {
    VCard,
    VDeleteAction,
    VDrag,
    VAction,
    VImage,
    VLine
  },
  props: {
    // trainingId
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    collapsedAll: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    // Misc
    const store = useStore();
    const router = useRouter();

    // Composables
    const { getText } = useTexts();
    const { trimHTML, lowercase } = useDisplay();
    const { request } = useRequest();
    const { route } = useLesson();
    const { getContrastingColor } = useColor();

    // Data
    const isCollapsed = ref(false);

    // Computed
    const languages = computed(() => {
      const langs = store.getters["app/available_languages"];
      // eslint-disable-next-line
      const resourceLangs = props.resource?.texts?.map(text => langs[text.language]);

      return resourceLangs.join(", ");
    });

    // Methods
    const onClickToggle = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    const onClickDelete = async () => {
      await request({
        endpoint: "teaching.trainings.remove-outline-item",
        pathParams: {
          id: props.id,
          type: props.resource?.outline_object_type,
          item_id: props.resource?.id
        },
        showToaster: false
      });

      context.emit("refresh");
    };

    const onClickRedirect = () => {
      const url = router.resolve({
        name: `${route}-details`,
        params: {
          id: props.resource.original_id
        }
      });

      window.open(url.href, "_blank");
    };

    // Watch
    watch(
      () => props.collapsedAll,
      () => {
        isCollapsed.value = props.collapsedAll;
      }
    );

    return {
      onClickRedirect,
      languages,
      isCollapsed,
      onClickToggle,
      onClickDelete,
      // useColor
      getContrastingColor,
      // useDisplay
      lowercase,
      trimHTML,
      // useTexts
      getText
    };
  }
};
</script>
