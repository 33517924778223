<template>
  <div :class="{ 'intro-y': hasAnimation }">
    <VDropdown
      :selected="lang"
      :options="languages"
      @click="$emit('update', $event)"
    >
      <template #option="{ option }">
        <div class="flex justify-between p-2 cursor-pointer">
          <div class="truncate">
            {{ option.text }}
          </div>

          <div @click.stop="onClickClear(option)">
            <VAction
              icon="ban"
              :disabled="isDisabled(option.value)"
              :class="{ 'text-gray-400': isDisabled(option.value) }"
              :label="$t('app.delete_texts')"
            />
          </div>
        </div>
      </template>
    </VDropdown>

    <DeleteModal
      v-if="isModalVisible"
      :id="id"
      :message="deleteMessage"
      :is-deleting="isDeleting"
      @click:cancel="hideModal"
      @click:delete="onClickDelete"
    />
  </div>
</template>

<script>
import { ref, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
import useModal from "@/composables/useModal";
import useAnimation from "@/composables/useAnimation";
import useOptions from "@/composables/useOptions";
// Components
import VDropdown from "@/components/VDropdown";
import DeleteModal from "@/components/modals/DeleteModal";
import VAction from "@/components/tables/VAction";

export default {
  components: {
    VDropdown,
    DeleteModal,
    VAction
  },
  props: {
    lang: {
      type: String,
      default: ""
    },
    texts: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    endpoint: {
      type: String,
      default: ""
    },
    attribute: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    }
  },
  emits: ["update", "cleared", "click:clear"],
  setup(props, context) {
    // MISC
    const { t } = useI18n();

    // Inject
    const _ = inject("lodash");

    // Data
    const id = ref(_.uniqueId());
    const deletable = ref({});

    // Composables
    const { hasAnimation } = useAnimation();
    const { langs } = useOptions();
    const { isModalVisible, showModal, hideModal } = useModal(`#${id.value}`);
    const { isRequesting: isDeleting, request } = useRequest();

    // CONSTANTS
    const allLang = {
      text: t("app.all_languages"),
      value: ""
    };

    // COMPUTED
    const languages = computed(() => [...langs.value, allLang]);
    // eslint-disable-next-line
    const deleteMessage = computed(() => {
      if (props.message) {
        return props.message;
      }

      return t("app.delete_text", { name: deletable.value?.name });
    });

    // METHODS
    const isDisabled = lang => props.disabled || !hasText(lang);
    const hasText = lang => props.texts.some(text => text.language === lang);

    const onClickClear = item => {
      if (isDisabled(item.value)) {
        return;
      }

      const text = props.texts.find(text => text.language === item.value) || {};

      deletable.value = {
        ...item,
        ...text,
        name: item.text
      };

      context.emit("click:clear", deletable.value);

      showModal();
    };

    const onClickDelete = async () => {
      await request({
        endpoint: props.endpoint,
        pathParams: {
          id: deletable.value[props.attribute],
          text_id: deletable.value.id
        },
        data: {
          language: deletable.value?.language
        }
      });

      hideModal();

      context.emit("cleared", deletable);
    };

    return {
      deleteMessage,
      id,
      hasText,
      isDisabled,
      languages,
      onClickClear,
      deletable,
      onClickDelete,
      // useRequest
      isDeleting,
      // useModal
      isModalVisible,
      hideModal,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
