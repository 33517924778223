<!-- eslint-disable -->
<template>
  <VCard :collapsed="isCollapsed" title-class="bg-theme-5">
    <template #title>
      <div class="flex items-center">
        <VImage
          :color="resource.colour"
          :name="getText(resource.texts, 'name')"
          class="mr-4"
        />

        <div>
          <div class="label text-xs font-light uppercase">
            {{ $t(`backend.${resource.outline_object_type}`) }}
          </div>

          {{ getText(resource?.texts, "name") }}
        </div>
      </div>
    </template>

    <template #title-actions>
      <div class="flex items-center">
        <VDrag :readonly="readonly" />

        <VDeleteAction
          :disabled="readonly"
          :item="resource"
          :text-value="getText(resource?.texts, 'name')"
          :delete-function="onClickDelete"
        />

        <VAction
          :icon="isCollapsed ? 'expand' : 'collapse'"
          :label="$t(`app.${isCollapsed ? 'expand' : 'collapse'}`)"
          @click="onClickToggle"
        />

        <VAction
          icon="redirect"
          :label="$t('app.redirect')"
          @click="onClickRedirect"
        />
      </div>
    </template>

    <template #content>
      <div class="mb-4">
        {{ trimHTML(getText(resource?.texts, "details")) }}
      </div>

      <div class="grid gap-2">
        <div class="flex justify-between items-start gap-4">
          <div class="w-full">
            <VSelect
              v-model="v.approve_by.$model"
              :label="$t('app.approve_by')"
              :class="{ 'mb-4': v.approve_by.$model === ALL_OPTIONS.INSTRUCTOR.value }"
              :errors="v.approve_by.$errors"
              :options="options.approve_by"
              @keyup.enter="onClickSave"
            />

            <VSelect
              v-if="v.approve_by.$model === ALL_OPTIONS.INSTRUCTOR.value"
              v-model="v.approver_id.$model"
              :label="$t('app.approver_id')"
              :errors="v.approver_id.$errors"
              :options="options.approver_id"
              @keyup.enter="onClickSave"
            />
          </div>

          <div class="w-full">
            <VSelect
              v-model="v.submit_by.$model"
              :label="$t('app.submit_by')"
              :errors="v.submit_by.$errors"
              :class="{ 'mb-4': v.submit_by.$model === ALL_OPTIONS.INSTRUCTOR.value }"
              :options="options.submit_by"
              @keyup.enter="onClickSave"
            />

            <VSelect
              v-if="v.submit_by.$model === ALL_OPTIONS.INSTRUCTOR.value"
              v-model="v.submitter_id.$model"
              :label="$t('app.submitter_id')"
              :errors="v.submitter_id.$errors"
              :options="options.submitter_id"
              @keyup.enter="onClickSave"
            />
          </div>

          <div class="w-full">
            <VText
              v-model="v.maximum_failed_attempts.$model"
              :label="$t('app.maximum_failed_attempts')"
              :errors="v.maximum_failed_attempts.$errors"
              class="w-full mb-4"
              type="number"
              @keyup.enter="onClickSave"
            />

            <VSelect
              v-model="v.assign_competencies_upon.$model"
              :readonly="!hasRequireApproval"
              :label="$t('app.assign_competencies_upon')"
              :errors="v.assign_competencies_upon.$errors"
              :options="options.assign_competencies_upon"
              @keyup.enter="onClickSave"
            />
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <div class="flex justify-end items-center">
        <VButton
          class="btn-primary"
          :label="$t('app.save')"
          :is-loading="isUpdating"
          @click="onClickSave"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { onMounted, computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
// Composables
import useTrainingAttestation from "@/composables/useTrainingAttestation";
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
import useRequest from "@/composables/useRequest";
import useColor from "@/composables/useColor";
import useForm from "@/composables/useForm";
import useOptions from "@/composables/useOptions";
// Components
import VCard from "@/components/VCard";
import VDeleteAction from "@/components/VDeleteAction";
import VAction from "@/components/tables/VAction";
import VDrag from "@/components/VDrag";
import VImage from "@/components/VImage";
import VButton from "@/components/VButton";
import VSelect from "@/components/inputs/VSelect";
import VText from "@/components/inputs/VText";

export default {
  components: {
    VText,
    VButton,
    VSelect,
    VCard,
    VDeleteAction,
    VDrag,
    VAction,
    VImage
  },
  props: {
    // trainingId
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    collapsedAll: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    // Misc
    const router = useRouter();

    // Data
    const isCollapsed = ref(false);

    // Computed
    const hasRequireApproval = computed(() => {
      // eslint-disable-next-line
      return props.resource.requires_completion_approval === ALL_OPTIONS.YES.value;
    });

    // Composables
    const { getText } = useTexts();
    const { trimHTML, lowercase } = useDisplay();
    const { request } = useRequest();
    const { isRequesting: isUpdating, request: update } = useRequest();
    const {
      getOptions,
      route,
      endpoint,
      value,
      rules,
      options
    } = useTrainingAttestation({
      attestation: props.resource.pivot
    });
    const { getContrastingColor } = useColor();
    const { ALL_OPTIONS } = useOptions();
    const { v, onSave } = useForm(props, context, {
      value,
      rules
    });

    // Methods
    const onClickToggle = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    const onClickDelete = async () => {
      await request({
        endpoint: "teaching.trainings.remove-outline-item",
        pathParams: {
          id: props.id,
          type: props.resource?.outline_object_type,
          item_id: props.resource?.id
        },
        showToaster: false
      });

      context.emit("refresh");
    };

    const onClickRedirect = () => {
      const url = router.resolve({
        name: `${route}-details`,
        params: {
          id: props.resource.original_id
        }
      });

      window.open(url.href, "_blank");
    };

    const onClickSave = async () => {
      const data = await onSave();

      await update({
        endpoint,
        pathParams: {
          id: props.id,
          attestation_id: props.resource?.pivot?.attestation_id
        },
        data
      });
    };

    // Watch
    watch(
      () => props.collapsedAll,
      () => {
        isCollapsed.value = props.collapsedAll;
      }
    );

    // Lifecycle Hooks
    onMounted(async () => {
      await getOptions();
    });

    return {
      hasRequireApproval,
      onClickRedirect,
      onClickToggle,
      isCollapsed,
      onClickDelete,
      onClickSave,
      // useRequest
      isUpdating,
      // useOptions
      ALL_OPTIONS,
      // useForm
      v,
      // useAttestation
      options,
      // useColor
      getContrastingColor,
      // useDisplay
      trimHTML,
      lowercase,
      // useTexts
      getText
    };
  }
};
</script>
