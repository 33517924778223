<template>
  <div>
    <div class="flex items-center justify-between mb-10">
      <div class="text-base font-medium">
        {{ $t("app.manage_competencies") }}
      </div>

      <div class="flex">
        <VSelect
          v-model="option"
          :options="options"
          :readonly="readonly"
          :placeholder="$t('app.add_competency')"
          class="w-96 flex mr-2"
          :load="load"
          is-fetched
        />

        <VButton
          :disabled="readonly"
          icon="fa-plus"
          class="btn-primary"
          @click="onClickAddCompetency"
        />
      </div>
    </div>

    <VTable :headers="headers" :rows="displayedCompetencies">
      <!-- eslint-disable-next-line -->
      <template #item.name="{ item }">
        {{ getText(item.texts, "name") }}
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.valid_until="{ item }">
        <VDatePicker
          :model-value="item.valid_until"
          :readonly="readonly"
          @update:model-value="onUpdateValidUntil(item, $event)"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.days_valid="{ item }">
        <VText
          :model-value="item.days_valid"
          :readonly="readonly"
          type="number"
          @update:model-value="onUpdateDaysValid(item, $event)"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.__ACTIONS="{ item }">
        <div class="flex justify-end">
          <VDeleteAction
            :item="item"
            :disabled="readonly"
            :text-value="getText(item.texts, 'name')"
            :delete-function="onClickDelete"
          />
        </div>
      </template>
    </VTable>
  </div>
</template>

<script>
import { ref, inject, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
// Components
import VTable from "@/components/tables/VTable";
import VButton from "@/components/VButton";
import VText from "@/components/inputs/VText";
import VDatePicker from "@/components/inputs/VDatePicker";
import VSelect from "@/components/inputs/VSelect";
import VDeleteAction from "@/components/VDeleteAction";

export default {
  components: {
    VTable,
    VButton,
    VText,
    VDatePicker,
    VSelect,
    VDeleteAction
  },
  props: {
    id: {
      type: [Number, String],
      default: ""
    },
    competencies: {
      type: Array,
      default: () => []
    },
    endpoint: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["add:competency", "remove:competency"],
  setup(props, context) {
    // Misc
    const { t } = useI18n();
    const _ = inject("lodash");
    const moment = inject("moment");

    // Constants
    const headers = [
      {
        text: t("app.name"),
        value: "name"
      },
      {
        text: t("app.valid_until"),
        value: "valid_until"
      },
      {
        text: t("app.days_valid"),
        value: "days_valid"
      },
      {
        value: "__ACTIONS"
      }
    ];

    // Computed
    const competencyIds = computed(() => {
      return props.competencies.map(competency => {
        let value = {};

        if (competency.pivot?.valid_until) {
          value = { valid_until: competency.pivot.valid_until };
        } else if (competency.pivot?.days_valid) {
          value = { days_valid: competency.pivot.days_valid };
        }

        return { competency_id: competency.id, ...value };
      });
    });

    const displayedCompetencies = computed(() => {
      const lessonCompetencies = props.competencies.map(competency => {
        return {
          ...competency,
          name: getText(competency.texts, "name"),
          valid_until: competency.pivot?.valid_until,
          days_valid: competency.pivot?.days_valid
        };
      });

      return [...lessonCompetencies, ...addedCompetencies.value];
    });

    const addedFormattedCompetency = computed(() => {
      return addedCompetencies.value.map(competency => {
        let value = {};

        if (competency.valid_until) {
          value = {
            valid_until: moment(competency.valid_until).format("YYYY-MM-DD")
          };
        } else if (competency.days_valid) {
          value = { days_valid: competency.days_valid };
        }

        return { competency_id: competency.id, ...value };
      });
    });

    // Composables
    const { getText } = useTexts();
    const { isRequesting: isAdding, request: update } = useRequest();
    const { request: read } = useRequest();
    const { request: remove } = useRequest();

    // Data
    const option = ref("");
    const options = ref([]);
    const addedCompetencies = ref([]);

    // Methods
    const load = _.debounce(async v => {
      const filters = [
        {
          f: ["name"],
          c: "LIKE",
          v: [v]
        }
      ];

      const response = await read({
        endpoint: "teaching.competencies",
        queryParams: {
          ro_f: encodeURIComponent(JSON.stringify(filters)),
          ro_r: ["texts"]
        }
      });

      updateOptions(response?.payload?.data);
    });

    const updateOptions = updatedOptions => {
      options.value = updatedOptions.map(option => ({
        ...option,
        text: getText(option.texts, "name"),
        value: option.id,
        disabled: displayedCompetencies.value.some(competency => {
          return competency.id === option.id;
        })
      }));
    };

    const onSave = async () => {
      await update({
        endpoint: `${props.endpoint}.update`,
        pathParams: {
          id: props.id
        },
        data: {
          competencies: JSON.stringify([
            ...competencyIds.value,
            ...addedFormattedCompetency.value
          ])
        }
      });

      addedCompetencies.value = [];
      context.emit("add:competency");
    };

    const onClickAddCompetency = () => {
      if (!option.value) {
        return;
      }

      const selectedCompetency = options.value.find(o => o.id == option.value);

      // eslint-disable-next-line
      const valid_until = moment(new Date()).add(1, "y").format("YYYY-MM-DD");

      addedCompetencies.value.push({
        ...selectedCompetency,
        valid_until
      });
      option.value = "";
    };

    const onClickDelete = async item => {
      await remove({
        endpoint: `${props.endpoint}.competencies.delete`,
        pathParams: {
          id: props.id,
          competency_id: item.id
        }
      });

      context.emit("remove:competency");
    };

    const onUpdateValidUntil = (item, valid_until) => {
      item.valid_until = valid_until;
      item.days_valid = "";
    };

    const onUpdateDaysValid = (item, days_valid) => {
      item.days_valid = days_valid;
      item.valid_until = "";
    };

    // Watch
    watch(displayedCompetencies, () => {
      updateOptions(options.value);
    });

    return {
      addedCompetencies,
      headers,
      displayedCompetencies,
      load,
      onClickDelete,
      option,
      options,
      onClickAddCompetency,
      onSave,
      onUpdateValidUntil,
      onUpdateDaysValid,
      // useTexts
      getText,
      // useRequest
      isAdding
    };
  }
};
</script>
