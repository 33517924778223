<template>
  <div class="flex flex-wrap items-center">
    <slot :id="id" name="label">
      <label v-if="label" :for="id" class="form-label w-full">
        {{ label }}
      </label>
    </slot>

    <div class="flex items-center">
      <input
        :id="id"
        :checked="value"
        class="form-check-switch mr-2"
        type="checkbox"
        :disabled="disabled || readonly"
        @input="onInput"
      />

      <div v-if="!hideValue">
        {{ $t(`app.${modelValue}`) }}
      </div>
    </div>

    <VErrors :errors="errors" />
  </div>
</template>

<script>
import { computed } from "vue";
import InputMixin from "@/mixins/InputMixin";

export default {
  mixins: [InputMixin],
  props: {
    trueValue: {
      type: [String, Boolean],
      default: true
    },
    falseValue: {
      type: [String, Boolean],
      default: false
    },
    hideValue: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // COMPUTED
    const value = computed(() => props.modelValue === props.trueValue);

    // METHODS
    const onInput = e => {
      const value = e.target.checked ? props.trueValue : props.falseValue;
      emit("update:modelValue", value);
    };

    return {
      value,
      onInput
    };
  }
};
</script>

<style scoped>
.form-check-switch:disabled {
  cursor: not-allowed;
}
</style>
