<template>
  <div>
    <component
      :is="component"
      v-if="lang"
      class="lang-input-group"
      :model-value="modelValue[lang]?.value || ''"
      :options="getOptions(lang)"
      :label="label"
      :accept="accept"
      :errors="errors[lang]?.$errors"
      :readonly="readonly"
      @update:modelValue="onUpdate($event, lang)"
    />

    <div v-else>
      <component
        :is="component"
        v-for="(language, index) in languages"
        :key="language"
        class="lang-input-group"
        :model-value="modelValue[language]?.value || ''"
        :options="getOptions(language)"
        :readonly="readonly"
        :class="{ 'mt-2': index !== 0 }"
        :label="index === 0 ? label : ''"
        :accept="accept"
        :input-group="language.toUpperCase()"
        :errors="errors[language]?.$errors"
        @update:modelValue="onUpdate($event, language)"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
// Mixins
import InputMixin from "@/mixins/InputMixin";
// Composables
import useOptions from "@/composables/useOptions";
// Components
import VText from "@/components/inputs/VText";
import VWysiwyg from "@/components/inputs/VWysiwyg";
import VFile from "@/components/inputs/VFile";
import VUploadFile from "@/components/inputs/VUploadFile";

export default {
  components: {
    VText,
    VWysiwyg,
    VFile,
    VUploadFile
  },
  mixins: [InputMixin],
  props: {
    lang: {
      type: String,
      default: ""
    },
    component: {
      type: String,
      default: "VText"
    },
    modelValue: {
      type: Object,
      default: () => ({})
    },
    field: {
      type: String,
      required: true
    },
    accept: {
      type: String,
      default: "*"
    }
  },
  setup(props, { emit }) {
    const { langs } = useOptions();

    // COMPUTED
    const languages = computed(() => {
      return langs.value.map(x => x.value);
    });

    // METHODS
    const onUpdate = (value, lang) => {
      emit("update:modelValue", {
        lang,
        value,
        field: props.field
      });
    };

    const getOptions = lang => {
      const options = props.modelValue[lang]?.content_file;
      return options ? [options] : [];
    };

    return {
      onUpdate,
      languages,
      getOptions
    };
  }
};
</script>

<style scoped>
.lang-input-group > :deep(.input-group > .input-group-text) {
  width: 45px;
}
</style>
