<template>
  <VAction
    :label="label || $t('app.drag')"
    icon="move"
    cursor="move"
    :disabled="readonly"
    :class="{ 'drag-handle': !readonly }"
  />
</template>

<script>
// Components
import VAction from "@/components/tables/VAction";

export default {
  components: {
    VAction
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    }
  }
};
</script>
