<template>
  <div>
    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <VAlert v-else-if="rows.length === 0" :text="$t('app.no_results_found')" />

    <div v-else>
      <table class="table">
        <thead>
          <tr>
            <th
              v-if="multiple"
              class="border-b-2 dark:border-dark-5 whitespace-nowrap"
            >
              <VCheckbox
                :model-value="hasSelectedAll"
                @update:modelValue="selectAll"
              />
            </th>

            <th
              v-if="displayOrder"
              class="border-b-2 dark:border-dark-5 whitespace-nowrap"
            >
              #
            </th>

            <th
              v-for="(header, index) in headHeaders"
              :key="`header-${header.value}-${index}`"
              class="capitalize border-b-2 dark:border-dark-5 whitespace-nowrap"
              :class="{ 'image-padding': header.value === 'image' }"
            >
              <slot
                :name="`header.${header.value}`"
                :header="header"
                :index="index"
              >
                <div
                  class="flex"
                  :class="[
                    header.headerClass,
                    {
                      'justify-center':
                        header.value === '__ACTIONS' ||
                        header.value === 'status'
                    }
                  ]"
                >
                  {{ header.text }}
                </div>
              </slot>
            </th>

            <th
              v-if="hasSelectedMultiple"
              class="border-b-2 dark:border-dark-5 whitespace-nowrap"
            >
              <DropdownButton
                button-class="w-full"
                :label="$t('app.actions')"
                :options="actionOptions"
                @click="$event.click()"
              />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(row, rowIndex) in rows"
            :key="`${row}-${rowIndex}`"
            :class="row.rowClass"
            @click="$emit('click:row', row)"
          >
            <slot name="row" :item="row" :index="rowIndex">
              <td
                v-if="multiple"
                :class="[
                  { 'border-b': rowIndex !== rows.length - 1 },
                  'w-0 dark:border-dark-5'
                ]"
              >
                <VCheckbox
                  :model-value="hasItem(row.id)"
                  @update:modelValue="select(row)"
                />
              </td>

              <td
                v-if="displayOrder"
                :class="[
                  { 'border-b': rowIndex !== rows.length - 1 },
                  'dark:border-dark-5'
                ]"
              >
                {{ rowIndex + 1 }}
              </td>

              <td
                v-for="(header, headerIndex) in headers"
                :key="`value-${header.value}-${headerIndex}`"
                class="dark:border-dark-5"
                :class="[
                  { 'border-b': rowIndex !== rows.length - 1 },
                  header.cellClass,
                  header.class,
                  { 'image-padding': header.value === 'image' }
                ]"
              >
                <slot
                  :name="`item.${header.value}`"
                  :item="row"
                  :index="rowIndex"
                >
                  {{ row[header.value] }}
                </slot>
              </td>
            </slot>
          </tr>
        </tbody>
      </table>

      <div
        v-if="displayPagination"
        class="flex justify-between items-center mt-3"
      >
        <CurrentPageElements
          :page="pagination.current_page"
          :per-page="pagination.per_page"
          :total="pagination.total"
        />

        <VPagination
          :model-value="pagination.current_page"
          :per-page="pagination.per_page"
          :total="pagination.total"
          @update:modelValue="$emit('update:current_page', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Composables
import useTable from "@/composables/useTable";
// Components
import VPagination from "@/components/tables/VPagination.vue";
import CurrentPageElements from "@/components/tables/CurrentPageElements.vue";
import VAlert from "@/components/VAlert";
import VCheckbox from "@/components/inputs/VCheckbox";
import DropdownButton from "@/components/DropdownButton";

export default {
  components: {
    VPagination,
    CurrentPageElements,
    VAlert,
    VCheckbox,
    DropdownButton
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Array,
      default: () => []
    },
    displayOrder: {
      type: Boolean,
      default: false
    },
    displayPagination: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Object,
      default: () => ({
        current_page: 1,
        per_page: 20,
        total: 0
      })
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:current_page"],
  setup(props, context) {
    const {
      hasSelectedMultiple,
      hasSelectedAll,
      selected,
      hasItem,
      selectAll,
      select,
      headHeaders,
      actionOptions
    } = useTable(props, context);

    return {
      hasSelectedMultiple,
      hasSelectedAll,
      selected,
      hasItem,
      selectAll,
      select,
      headHeaders,
      actionOptions
    };
  }
};
</script>

<style scoped>
.image-padding {
  padding-right: 0px !important;
}
</style>
