<template>
  <div>
    <div class="flex justify-between items-center">
      <VSearchResource
        :label="$t('app.lessons', 2)"
        :readonly="readonly"
        :load="loadLessons"
        :format-options="formatLessons"
        @add="onAdd($event, TYPES.LESSONS)"
      />

      <VSearchResource
        :label="$t('app.evaluations', 2)"
        :readonly="readonly"
        :load="loadEvaluations"
        :format-options="formatEvaluations"
        @add="onAdd($event, TYPES.EVALUATIONS)"
      />

      <VSearchResource
        :label="$t('app.attestations', 2)"
        :readonly="readonly"
        :load="loadAttestations"
        :format-options="formatAttestations"
        @add="onAdd($event, TYPES.ATTESTATIONS)"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useTexts from "@/composables/useTexts";
import useOptions from "@/composables/useOptions";
import useLesson from "@/composables/useLesson";
import useAttestation from "@/composables/useAttestation";
import useEvaluation from "@/composables/useEvaluation";
// Components
import VSearchResource from "@/components/inputs/VSearchResource";

export default {
  components: {
    VSearchResource
  },
  props: {
    resource: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["add"],
  setup(props, context) {
    // Composables
    const { endpoint: lEndpoint, searchFields: lFields } = useLesson();
    const { endpoint: aEndpoint, searchFields: aFields } = useAttestation();
    const { endpoint: eEndpoint, searchFields: eFields } = useEvaluation();
    const { loadOptions, formatOptions } = useOptions();
    const { getText } = useTexts();

    // Constants
    const TYPES = {
      LESSONS: "lessons",
      EVALUATIONS: "evaluations",
      ATTESTATIONS: "attestations"
    };

    const endpoints = {
      [TYPES.LESSONS]: lEndpoint,
      [TYPES.ATTESTATIONS]: aEndpoint,
      [TYPES.EVALUATIONS]: eEndpoint
    };

    const searchFields = {
      [TYPES.LESSONS]: lFields,
      [TYPES.ATTESTATIONS]: aFields,
      [TYPES.EVALUATIONS]: eFields
    };

    // Computed
    const lessonIds = computed(() => {
      return props.resource.lessons.map(l => l.id);
    });

    const attestationIds = computed(() => {
      return props.resource.attestations.map(a => a.id);
    });

    const evaluationIds = computed(() => {
      return props.resource.evaluations.map(e => e.id);
    });

    const ids = computed(() => ({
      [TYPES.ATTESTATIONS]: attestationIds.value,
      [TYPES.LESSONS]: lessonIds.value,
      [TYPES.EVALUATIONS]: evaluationIds.value
    }));

    // Methods
    const onAdd = (id, type) => {
      context.emit("add", id, type);
    };

    const load = (query, resourceType) => {
      const filters = [
        {
          f: searchFields[resourceType],
          c: "LIKE",
          v: [query]
        },
        {
          f: "status",
          c: "EQUAL",
          v: "Active"
        }
      ];

      return loadOptions(endpoints[resourceType], {
        ro_f: encodeURIComponent(JSON.stringify(filters)),
        ro_r: ["texts"]
      });
    };

    const loadLessons = query => load(query, TYPES.LESSONS);
    const loadAttestations = query => load(query, TYPES.ATTESTATIONS);
    const loadEvaluations = query => load(query, TYPES.EVALUATIONS);

    const formatLoadOptions = (options, resourceType) => {
      const formatText = option => getText(option?.texts, "name");
      const formattedOptions = formatOptions(options, { formatText });

      return formattedOptions.map(option => ({
        ...option,
        disabled: ids.value[resourceType].includes(option.id)
      }));
    };

    const formatLessons = options => formatLoadOptions(options, TYPES.LESSONS);
    // eslint-disable-next-line
    const formatAttestations = options => formatLoadOptions(options, TYPES.ATTESTATIONS);
    // eslint-disable-next-line
    const formatEvaluations = options => formatLoadOptions(options, TYPES.EVALUATIONS);

    return {
      TYPES,
      onAdd,
      loadLessons,
      loadAttestations,
      loadEvaluations,
      formatLessons,
      formatAttestations,
      formatEvaluations
    };
  }
};
</script>
