<template>
  <div>
    <div class="flex items-center justify-between mb-10">
      <div class="text-base font-medium">
        {{ $t("app.manage_prerequisites") }}
      </div>

      <VSearchResource
        :readonly="readonly"
        :load="load"
        :format-options="formatLoadOptions"
        @add="onClickAdd"
      />
    </div>

    <VTable :headers="headers" :rows="prerequisites">
      <!-- eslint-disable-next-line -->
      <template #item.name="{ item }">
        {{ getText(item?.texts, "name") }}
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.__ACTIONS="{ item }">
        <div class="flex justify-end">
          <VDeleteAction
            :disabled="readonly"
            :item="item"
            :text-value="getText(item?.texts, 'name')"
            :delete-function="onClickDelete"
          />
        </div>
      </template>
    </VTable>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTraining from "@/composables/useTraining";
import useRequest from "@/composables/useRequest";
import useTexts from "@/composables/useTexts";
import useOptions from "@/composables/useOptions";
// Components
import VTable from "@/components/tables/VTable";
import VDeleteAction from "@/components/VDeleteAction";
import VSearchResource from "@/components/inputs/VSearchResource";

export default {
  components: {
    VTable,
    VDeleteAction,
    VSearchResource
  },
  props: {
    id: {
      type: [Number, String],
      default: ""
    },
    prerequisites: {
      type: Array,
      default: () => []
    },
    endpoint: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["add", "remove"],
  setup(props, context) {
    // Misc
    const { t } = useI18n();

    // Constants
    const headers = [
      {
        text: t("app.name"),
        value: "name"
      },
      {
        text: t("app.version"),
        value: "version"
      },
      {
        value: "__ACTIONS"
      }
    ];

    // Computed
    const prerequisiteIds = computed(() => {
      return props.prerequisites.map(prerequisite => prerequisite.id);
    });

    // Composables
    const { getText } = useTexts();
    const { isRequesting: isAdding, request: update } = useRequest();
    const { request } = useRequest();
    const { endpoint, searchFields } = useTraining();
    const { ALL_OPTIONS, loadOptions, formatOptions } = useOptions();

    // Methods
    const load = query => {
      const filters = [
        {
          f: searchFields,
          c: "LIKE",
          v: query
        },
        {
          f: "status",
          c: "EQUAL",
          v: ALL_OPTIONS.ACTIVE.value
        }
      ];

      return loadOptions(endpoint, {
        ro_f: encodeURIComponent(JSON.stringify(filters)),
        ro_r: ["texts"]
      });
    };

    const formatLoadOptions = options => {
      const formatText = option => getText(option?.texts, "name");
      const formattedOptions = formatOptions(options, { formatText });

      return formattedOptions.map(option => ({
        ...option,
        disabled: prerequisiteIds.value.includes(option.id)
      }));
    };

    const onClickAdd = async id => {
      await update({
        endpoint: `${props.endpoint}.update`,
        pathParams: {
          id: props.id
        },
        data: {
          prerequisites: JSON.stringify([...prerequisiteIds.value, id])
        }
      });

      context.emit("add");
    };

    const onClickDelete = async item => {
      await request({
        endpoint: `${props.endpoint}.prerequisites.delete`,
        pathParams: {
          id: props.id,
          prerequisite_id: item.id
        }
      });

      context.emit("remove");
    };

    return {
      headers,
      load,
      formatLoadOptions,
      onClickAdd,
      onClickDelete,
      // useText
      getText,
      // useRequest
      isAdding
    };
  }
};
</script>
