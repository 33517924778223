<template>
  <VueDraggableNext
    :list="value"
    handle=".drag-handle"
    @change="$emit('change', value)"
  >
    <slot />
  </VueDraggableNext>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";

export default {
  components: {
    VueDraggableNext
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  emits: ["change"]
};
</script>
