import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { required, maxLength } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";
// Constants
import inputTypes from "@/constants/inputTypes";

export default function useRole() {
  // Misc
  const { t } = useI18n();

  const {
    ALL_OPTIONS,
    defaultStatusOptions,
    defaultSystemOptions,
    getDepartments
  } = useOptions();

  // CONSTANTS
  const view_code = "Roles";
  const route = "administration-roles";
  const endpoint = "administration.roles";
  const searchFields = ["name", "code"];
  const logType = "Role";
  const types = {
    name: inputTypes.TEXT,
    code: inputTypes.TEXT,
    system_role: inputTypes.SELECT,
    details: inputTypes.TEXT,
    department_id: inputTypes.SELECT,
    colour: inputTypes.COLOR,
    status: inputTypes.SELECT
  };
  const headers = [
    {
      value: "image",
      class: "w-10"
    },
    {
      text: t("app.roles"),
      value: "name"
    },
    {
      text: t("app.code"),
      value: "code",
      headerClass: "justify-center",
      class: "text-center"
    },
    {
      text: t("app.users", 2),
      value: "user_count",
      headerClass: "justify-end",
      class: "text-right"
    },
    {
      text: t("app.status"),
      value: "status",
      class: "w-40"
    },
    {
      text: t("app.actions"),
      value: "__ACTIONS",
      class: "w-56"
    }
  ];

  // DATA
  const value = ref({
    name: null,
    code: null,
    system_role: ALL_OPTIONS.NO.value,
    details: "",
    department_id: "",
    colour: "",
    status: ALL_OPTIONS.ACTIVE.value
  });
  const rules = ref({
    name: { required },
    code: { required, maxLength: maxLength(5) },
    system_role: { required },
    details: {},
    department_id: { required },
    colour: {},
    status: { required }
  });
  const options = ref({
    department_id: [],
    system_role: defaultSystemOptions,
    status: defaultStatusOptions
  });

  // METHODS
  const getOptions = async () => {
    options.value.department_id = await getDepartments();

    return options.value;
  };

  return {
    headers,
    logType,
    route,
    endpoint,
    searchFields,
    value,
    options,
    rules,
    types,
    view_code,
    getOptions,
    getDepartments
  };
}
