<template>
  <a
    :class="[
      selected ? 'active' : '',
      disabled
        ? 'text-theme-7 text-opacity-20 cursor-default'
        : 'cursor-pointer'
    ]"
    class="py-4 sm:mr-8"
    role="tab"
    :data-test="`${name}-tab`"
    :aria-controls="name"
    :aria-selected="selected"
    @click="onClick"
  >
    <slot>
      <i v-if="icon" :class="icon" />
      <span v-if="label" :class="lowercaseLabel ? '' : 'uppercase'">
        {{ label }}
      </span>
    </slot>
  </a>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    selectedTab: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    lowercaseLabel: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  setup(props, context) {
    // COMPUTED
    const selected = computed(() => props.selectedTab === props.name);

    // METHODS
    const onClick = () => {
      if (props.disabled) {
        return;
      }

      context.emit("click", props.name);
    };

    return {
      selected,
      onClick
    };
  }
};
</script>
