import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";
// Constants
import inputTypes from "@/constants/inputTypes";
import logTypes from "@/constants/logTypes";

export default function useLesson() {
  // Misc
  const { t } = useI18n();

  const {
    ALL_OPTIONS,
    defaultStatusOptions,
    defaultSystemOptions
  } = useOptions();

  // CONSTANTS
  const log_type = logTypes.ATTESTATION;
  const view_code = "Attestations";
  const route = "teaching-attestations";
  const endpoint = "teaching.attestations";
  const searchFields = ["name"];
  const localizedFields = ["name", "details"];
  const types = {
    name: inputTypes.TEXT,
    details: inputTypes.TEXT,
    status: inputTypes.SELECT,
    sys_admin_locked: inputTypes.SELECT,
    default_approve_by: inputTypes.SELECT,
    default_submit_by: inputTypes.SELECT,
    tags: inputTypes.TAGS,
    default_maximum_failed_attempts: inputTypes.NUMBER,
    default_assign_competencies_upon: inputTypes.SELECT
  };
  const headers = [
    {
      value: "image",
      class: "w-10"
    },
    {
      text: t("app.name"),
      value: "name"
    },
    {
      text: t("app.default_submit_by"),
      value: "default_submit_by"
    },
    {
      text: t("app.status"),
      value: "status",
      class: "w-40"
    },
    {
      text: t("app.actions"),
      value: "__ACTIONS",
      class: "w-56"
    }
  ];

  // DATA
  const value = ref({
    name: null,
    details: null,
    status: ALL_OPTIONS.ACTIVE.value,
    sys_admin_locked: ALL_OPTIONS.NO.value,
    default_approve_by: ALL_OPTIONS.ANY_INSTRUCTOR.value,
    default_submit_by: ALL_OPTIONS.LEARNER.value,
    default_maximum_failed_attempts: 5,
    default_assign_competencies_upon: ALL_OPTIONS.LEARNER_COMPLETION.value,
    tags: ""
  });

  const rules = ref({
    name: { required },
    details: {},
    status: { required },
    sys_admin_locked: { required },
    default_approve_by: { required },
    default_submit_by: { required },
    tags: {},
    default_maximum_failed_attempts: {},
    default_assign_competencies_upon: { required }
  });

  const options_by = [ALL_OPTIONS.ANY_INSTRUCTOR, ALL_OPTIONS.INSTRUCTOR];

  const options = ref({
    sys_admin_locked: defaultSystemOptions,
    status: defaultStatusOptions,
    default_approve_by: [ALL_OPTIONS.NONE, ...options_by],
    default_submit_by: [ALL_OPTIONS.LEARNER, ...options_by],
    default_assign_competencies_upon: [
      ALL_OPTIONS.LEARNER_COMPLETION,
      ALL_OPTIONS.COMPLETION_APPROVAL
    ]
  });

  return {
    headers,
    log_type,
    view_code,
    types,
    route,
    endpoint,
    searchFields,
    value,
    rules,
    options,
    localizedFields
  };
}
