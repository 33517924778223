import { ref } from "vue";
import { required } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";

export default function useTrainingAttestation(args) {
  const { attestation } = args;

  const { ALL_OPTIONS, getInstructors } = useOptions();

  // CONSTANTS
  const route = "teaching-attestations";
  const endpoint = "teaching.trainings.outline.update";

  // DATA
  const value = ref({
    submit_by: attestation.submit_by,
    submitter_id: attestation.submitter_id,
    approve_by: attestation.approve_by,
    approver_id: attestation.approver_id,
    maximum_failed_attempts: attestation.maximum_failed_attempts,
    assign_competencies_upon:
      attestation.assign_competencies_upon ||
      ALL_OPTIONS.LEARNER_COMPLETION.value
  });
  const rules = ref({
    submit_by: { required },
    submitter_id: {},
    approve_by: { required },
    approver_id: {},
    maximum_failed_attempts: { required },
    assign_competencies_upon: { required }
  });
  const options_by = [ALL_OPTIONS.ANY_INSTRUCTOR, ALL_OPTIONS.INSTRUCTOR];
  const options = ref({
    submitter_id: [],
    approver_id: [],
    approve_by: [ALL_OPTIONS.NONE, ...options_by],
    submit_by: [ALL_OPTIONS.LEARNER, ...options_by],
    assign_competencies_upon: [
      ALL_OPTIONS.LEARNER_COMPLETION,
      ALL_OPTIONS.COMPLETION_APPROVAL
    ]
  });

  // Methods
  const getOptions = async () => {
    const instructors = await getInstructors();
    options.value.submitter_id = instructors;
    options.value.approver_id = instructors;

    return options.value;
  };

  return {
    route,
    endpoint,
    value,
    rules,
    options,
    getOptions
  };
}
