import VWysiwyg from "@/components/inputs/VWysiwyg";
import VText from "@/components/inputs/VText";
import VSelect from "@/components/inputs/VSelect";
import VSwitch from "@/components/inputs/VSwitch";
import VColor from "@/components/inputs/VColor";
import LocalizedInput from "@/components/inputs/LocalizedInput";
import VDatePicker from "@/components/inputs/VDatePicker";
import VTags from "@/components/inputs/VTags";
import VFile from "@/components/inputs/VFile";
import VRadio from "@/components/inputs/VRadio";
import VCheckbox from "@/components/inputs/VCheckbox";
import FormCard from "@/components/FormCard";

export default {
  components: {
    VWysiwyg,
    VSwitch,
    VText,
    VSelect,
    VColor,
    FormCard,
    LocalizedInput,
    VDatePicker,
    VTags,
    VFile,
    VRadio,
    VCheckbox
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click:save"]
};
