export default {
  DEPARTMENT: "Department",
  SITE: "Site",
  ROLE: "Role",
  USER: "User",
  LOGIN: "Login",
  COMPETENCY: "Competency",
  LESSON: "Lesson",
  ATTESTATION: "Attestation"
};
